import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
       <h1>This Website is under Construction</h1> 
       <p>
        If you want any clarification call: +91-9910070347
      </p>
      </header>
     
    </div>
  );
}

export default App;
